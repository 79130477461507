.main-new-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.LandingPage {
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
}

.landing-main {
    margin: 0 auto;
    
}

.landing-main h1 {
    font-size: 60px;
    margin: 0;
}

.landing-main h4 {
    font-size: 30px;
}

.landing-main button {
    width: 30%;
    margin: 20px auto;
    border-radius: 20px;
    padding: 10px 0;
    font-weight: 800;
    font-size: 15px;
}

.landing-main button:focus {
    outline: none;
}

.landing-desc {
    text-align: left;
    float: left;
    padding: 0 40px;
    height: 100%;
}

.LandingPage img {
    float: right;
    max-height: calc(90vh - 20vh);
    max-width: 40%;
    margin-left: auto;
    border-radius: 5px;
}

@media(max-width:1250px){
    .LandingPage img {
        text-align: center;
        float: none;
        transform: rotate(90deg)
    }
    .landing-desc {
        text-align: center;
    }
}

@media(max-width:768px){
    .landing-main h1 {
        font-size: 20px;
    }
}