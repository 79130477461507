.LoginPage {
    height: 70%;
    margin: 0 auto;
    width: 40%;
}

.LoginPage h2 {
    margin: 0;
}

.LoginForm label {
    display: none;
}

.LoginForm input {
    border: none;
    border-bottom: 1px solid gray;
    background-color: #F1FFFA;
}

.LoginForm input {
    margin: 15px 0;
    width: 60%;
    font-size: 20px;
}

.LoginForm input:focus {
    outline: none;
}

.LoginForm button {
    width: 30%;
    margin-top: 30px;
    padding: 10px;
    border-radius: 20px;
    border: none;
    background-color: rgb(255, 255, 255);
}

.LoginForm button:focus {
    outline: none;
}

.LoginForm button:active {
    background-color: rgb(163, 163, 163);
}

@media(max-width:768px){
    .LoginPage {
        width: 70%;
    }
}