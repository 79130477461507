.entry {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background: #1A1916;
    width: 65%;
    height: 80vh;
    overflow: hidden;
    position: relative;
    top: 5vh;
    border-radius: 5px;
}

.entry-face {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 45%;
    justify-content: center;
    align-items: center;
}

.entry-face h2 {
    color: thistle;
}

.entry-graph {
    box-sizing: border-box;
    background: #1A1916;
    width: 55%;
}

.entry-graph .donut {
    padding-top: 15px;
    height: 50vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.donut h3 {
    color: thistle;
}

.doughnut {
    width: 100%;
}

.entry-notes {
    background: rgb(100, 100, 100);
    width: 80%;
    margin: 0 auto;
    height: 250px;
    text-align: left;
    border-radius: 4px 4px 0 0;
    padding: 10px 0 0 15px;
}

.entry iframe {
    width: 50vh;
    /* height: 80px; */
    border-radius: 0 0 5px 5px;
}

.calendar-back-arrow {
    position: absolute;
    font-size: 40px;
    left: 40px;
    top: 18%;
    transition: .4s ease-in-out
}

.calendar-back-arrow:hover,
.calendar-back-arrow:active {
    transform: translateX(-10px);
    color: darkgray;
}