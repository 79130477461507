.camera {
    position: relative;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
}

.camera-page, .calendar-page {
    background: #F1FFFA;
    margin-top: 5vh;
}

.current-img {
    width: 768px;
    height: auto;
    position: relative;
    margin: 0 auto;
}

.current-img img {
    width: 100%;
    height: auto;
    /* position: absolute; */
    left: 0;
    top: 0;
    z-index: 1;
}

.current-img button {
    font-size: 20px;
    padding: 15px;
    border-radius: 2.5px;
    background-color: white;
    box-shadow: 0 !important;
}

.current-img button:hover {
    background: black;
    border: white;
    color: white;
    outline: none;
}

.current-img .analyze-img {
    position: absolute;
    left: 20px;
    bottom: 10px;
    z-index: 100;
}

.current-img .delete-img {
    position: absolute;
    right: 20px;
    bottom: 10px;
    z-index: 100;
}

.emotion-graph {
    border-radius: 50px;
}

.emotion-graph img {
    margin-bottom: 30px;
}

.emotion-graph form {
    padding: 15px;
}

.emotion-graph textarea {
    width: 50%;
    border-radius: 5px;
    height: 30vh;
    padding: 5px;
}

.emotion-graph textarea:focus {
    outline: none;
}

.hidden {
    visibility: hidden;
    height: 576px;
    padding: 5px;
}

.home-photo {
    border-radius: 4px;
}

.photo-booth video {
    border-radius: 10px;
}

.recommendation-list {
    display: flex;
    flex-flow: wrap;
    margin: 0 auto;
    justify-content: center;
}

a {
    text-decoration: none;
    color: black;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.warning {
    z-index: 100;
}

@media(max-width:768px){
    .current-img {
        width: 100%;
    }
    .emotion-graph img {
        width: 80%;
    }
}
